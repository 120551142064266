import Description from '../components/description'
import Features from '../components/home-page/features/Features'
import Hero from '../components/home-page/hero/Hero'

import Testimony from '../components/home-page/testimony/Testimony'
import HomePageHead from '../components/heads/HomePageHead'
import { GetStaticProps } from 'next'
import { GET_FEATURES_LIST_QUERY } from '../graphql/company'
import apolloClient from '../apollo-client'
import ICompany from '../types/Company'
import { parseVideoData } from '../lib/Utils'

const MAX_SLIDER_PER_VIEW = 6

export default function Home({ data }: { data: any[] }) {
  return (
    <>
      <HomePageHead />
      <Hero />
      <Features featuredCompanies={data} />
      <Description />
      <Testimony />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  // eslint-disable-next-line no-console
  console.log('getStaticProps of home page executed')
  try {
    const { data } = await apolloClient.query({
      query: GET_FEATURES_LIST_QUERY,
      variables: {
        query: {
          campaignPackageWeightFrom: 0.9,
          hasVideo: true
        }
      }
    })

    const clientVideos: any[] = []
    data.clientsByJobPostFieldsV2.items.forEach((company: ICompany) => {
      const videoLink = company.defaultJobPost.videoLink

      if (!videoLink) {
        return
      }

      const parsedVideo = parseVideoData(videoLink, 'mqdefault.jpg')
      if (!parsedVideo) {
        return
      }
      if (
        company.defaultJobPost.videoPlaceholder &&
        company.defaultJobPost.videoPlaceholder.Location
      ) {
        parsedVideo.src = company.defaultJobPost.videoPlaceholder.Location
      }

      clientVideos.push({
        _id: company._id,
        videoData: parsedVideo,
        name: company.name,
        slug: company.defaultJobPost?.slug
      })
    })

    return {
      revalidate: 60 * 20,
      props: {
        data:
          MAX_SLIDER_PER_VIEW <= clientVideos.length * 2 // needed for loop feature
            ? [...clientVideos, ...clientVideos]
            : clientVideos
      }
    }
  } catch (e) {
    return {
      revalidate: 60 * 20,
      props: {
        data: []
      }
    }
  }
}
